<template>
  <div id="orderOut">
    <el-dialog
      :title="orderOutFormTitle"
      width="1200px"
      :visible.sync="orderOutDialogVisible"
      :close-on-click-modal="false"
      @close="orderOutDialogClose"
    >
      <el-form
        ref="orderOutFormRef"
        :model="orderOutForm"
        :rules="orderOutFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="内外销" prop="type">
              <el-radio-group
                v-model="orderOutForm.type"
                :disabled="orderOutFormTitle !== '新增出库单'
                  && orderOutFormTitle !== '修改出库单'
                  && orderOutFormTitle !== '出库单详情'"
              >
                <el-radio
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.code"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="领料单编号" prop="materialRequisitionNo">
              <el-input v-model="orderOutForm.materialRequisitionNo" placeholder="请输入领料单编号">
                <i slot="suffix" class="el-input__icon el-icon-success" @click="selectMaterialRequisition" />
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="接收部门" prop="receiveDept">
              <el-select
                v-model="orderOutForm.receiveDept"
                placeholder="请选择接收部门"
                clearable
                :disabled="orderOutFormTitle !== '新增出库单'
                  && orderOutFormTitle !== '修改出库单'
                  && orderOutFormTitle !== '出库单详情'"
                @change="deptChange"
              >
                <el-option
                  v-for="item in ['生产设备部', '质量部', '采购部', '仓储部', '销售部']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="编号" prop="serialNumber">
              <el-input
                v-model="orderOutForm.serialNumber"
                placeholder="请输入编号"
                clearable
                :disabled="orderOutFormTitle !== '新增出库单'
                  && orderOutFormTitle !== '修改出库单'
                  && orderOutFormTitle !== '出库单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库日期" prop="outDate">
              <el-date-picker
                v-model="orderOutForm.outDate"
                placeholder="请选择出库日期"
                value-format="yyyy-MM-dd"
                :disabled="orderOutFormTitle !== '新增出库单'
                  && orderOutFormTitle !== '修改出库单'
                  && orderOutFormTitle !== '出库单详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="出库单">
          <vxe-toolbar
            v-if="orderOutFormTitle === '新增出库单'
              || orderOutFormTitle === '修改出库单'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
            @edit-actived="editActivedEvent"
          >
            <vxe-table-column field="materialNo" title="物料编号">
              <template v-slot="{row, column}">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择物料"
                  clearable
                  filterable
                  @change="materialPurchaseStockChange(row)"
                >
                  <el-option
                    v-for="item in materialPurchaseStockList"
                    :key="item.id"
                    :label="item.materialNo"
                    :value="item.materialNo"
                  >
                    <span style="float: left">{{ item.materialNo }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.productName }}</span>
                  </el-option>
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="materialName"
              title="物料名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="spec"
              title="规格型号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <!-- <vxe-table-column
              field="batchNo"
              title="批号"
              :edit-render="{
                name: '$select',
                props: {clearable: true},
                options: batchNoList,
                optionProps: {
                  label: 'batchNo',
                  value: 'batchNo'
                }
              }"
            /> -->
            <vxe-table-column field="batchNo" title="批号">
              <template v-slot="{row, column}">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择批号"
                  clearable
                  @focus="editActivedEvent({ row })"
                >
                  <el-option
                    v-for="item in batchNoList"
                    :key="item.id"
                    :label="item.batchNo"
                    :value="item.batchNo"
                  >
                    <span style="float: left">{{ item.batchNo }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ "结余数量：" + item.balanceQuantity }}</span>
                  </el-option>
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="quantity"
              title="数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="situationExplain"
              title="出库情况说明"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="orderOutFormTitle === '新增出库单'
                || orderOutFormTitle === '修改出库单'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="审核结果" prop="reviewerResult">
              <el-radio-group
                v-model="orderOutForm.reviewerResult"
                :disabled="orderOutFormTitle !== '审核出库单'
                  && orderOutFormTitle !== '出库单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认结果" prop="confirmerResult">
              <el-radio-group
                v-model="orderOutForm.confirmerResult"
                :disabled="orderOutFormTitle !== '确认出库单'
                  && orderOutFormTitle !== '出库单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="orderOutDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="orderOutFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input v-model="searchForm.serialNumber" placeholder="请输入编号" clearable />
      </el-form-item>
      <el-form-item label="内外销" prop="type">
        <el-select v-model="searchForm.type" placeholder="请选择内外销" clearable>
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['ORDER_OUT_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="orderOutPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="serialNumber" label="编号" />
      <el-table-column prop="receiveDept" label="接收部门" />
      <el-table-column label="出库日期">
        <template slot-scope="scope">
          <span v-if="scope.row.outDate">{{ scope.row.outDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="editor" label="编制人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editorDate">{{ scope.row.editorDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{ scope.row.reviewerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerResult === 1">通过</span>
          <span v-if="scope.row.reviewerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="confirmer" label="确认人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmerDate">{{ scope.row.confirmerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="确认结果">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmerResult === 1">通过</span>
          <span v-if="scope.row.confirmerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column label="内外销">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">内销</span>
          <span v-if="scope.row.type === 2">外销</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['ORDER_OUT_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['ORDER_OUT_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['ORDER_OUT_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核
          </el-button>
          <el-button
            v-if="checkPermission(['ORDER_OUT_CONFIRM']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleConfirm(scope.$index, scope.row)"
          >
            确认
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="orderOutPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addOrderOut,
  deleteOrderOut,
  updateOrderOut,
  selectOrderOutInfo,
  selectOrderOutList,
  reviewOrderOut,
  confirmOrderOut
} from '@/api/storage/orderOut'
import { selectMaterialPurchaseStockList } from '@/api/storage/materialPurchaseStock'
import moment from 'moment'
import { selectMaterialRequisitionByNo } from '@/api/universal/materialRequisition'
import { selectMaterialPurchaseList } from '@/api/storage/materialPurchase'
import { selectDictList } from '@/api/system/dict'

export default {
  data () {
    return {
      orderOutDialogVisible: false,
      orderOutFormTitle: '',
      orderOutForm: {
        id: '',
        serialNumber: '',
        receiveDept: '',
        outDate: '',
        reviewerResult: '',
        confirmerResult: '',
        detailJson: '',
        taskId: '',
        type: '',
        materialRequisitionNo: ''
      },
      orderOutFormRules: {
        serialNumber: [{ required: true, message: '编号不能为空', trigger: ['blur', 'change']}]
      },
      orderOutPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        serialNumber: '',
        type: ''
      },
      detailList: [],
      materialPurchaseStockList: [],
      batchNoList: [],
      cacheBatchNoList: [],
      typeList: []
    }
  },
  created () {
    selectOrderOutList(this.searchForm).then(res => {
      this.orderOutPage = res
    })
    selectMaterialPurchaseStockList({ type: 1 }).then(res => {
      this.materialPurchaseStockList = res.list
    })
    selectDictList(2).then(res => {
      this.typeList = res
    })
  },
  methods: {
    orderOutDialogClose () {
      this.$refs.orderOutFormRef.resetFields()
      this.detailList = []
    },
    orderOutFormSubmit () {
      if (this.orderOutFormTitle === '出库单详情') {
        this.orderOutDialogVisible = false
        return
      }
      this.$refs.orderOutFormRef.validate(async valid => {
        if (valid) {
          if (this.orderOutFormTitle === '新增出库单') {
            this.orderOutForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addOrderOut(this.orderOutForm)
          } else if (this.orderOutFormTitle === '修改出库单') {
            this.orderOutForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateOrderOut(this.orderOutForm)
          } else if (this.orderOutFormTitle === '审核出库单') {
            await reviewOrderOut(this.orderOutForm)
          } else if (this.orderOutFormTitle === '确认出库单') {
            await confirmOrderOut(this.orderOutForm)
          }
          this.orderOutPage = await selectOrderOutList(this.searchForm)
          this.orderOutDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.orderOutFormTitle = '新增出库单'
      this.orderOutDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteOrderOut(row.id)
        if (this.orderOutPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.orderOutPage = await selectOrderOutList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.orderOutFormTitle = '修改出库单'
      this.orderOutDialogVisible = true
      this.selectOrderOutInfoById(row.id)
    },
    handleReview (index, row) {
      this.orderOutFormTitle = '审核出库单'
      this.orderOutDialogVisible = true
      this.selectOrderOutInfoById(row.id)
    },
    handleConfirm (index, row) {
      this.orderOutFormTitle = '确认出库单'
      this.orderOutDialogVisible = true
      this.selectOrderOutInfoById(row.id)
    },
    handleInfo (index, row) {
      this.orderOutFormTitle = '出库单详情'
      this.orderOutDialogVisible = true
      this.selectOrderOutInfoById(row.id)
    },
    selectOrderOutInfoById (id) {
      selectOrderOutInfo(id).then(res => {
        this.orderOutForm.id = res.id
        this.orderOutForm.serialNumber = res.serialNumber
        this.orderOutForm.receiveDept = res.receiveDept
        this.orderOutForm.outDate = res.outDate
        this.orderOutForm.reviewerResult = res.reviewerResult
        this.orderOutForm.confirmerResult = res.confirmerResult
        this.orderOutForm.taskId = res.taskId
        this.orderOutForm.type = res.type
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectOrderOutList(this.searchForm).then(res => {
        this.orderOutPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectOrderOutList(this.searchForm).then(res => {
        this.orderOutPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectOrderOutList(this.searchForm).then(res => {
        this.orderOutPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['ORDER_OUT_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['ORDER_OUT_CONFIRM']) && row.status === 2) {
        return 'review'
      }
      return ''
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    deptChange (value) {
      if (value === '生产设备部') {
        this.orderOutForm.serialNumber = 'C' + moment().format('YYYYMMDD') + '-SCSB-'
      } else if (value === '质量部') {
        this.orderOutForm.serialNumber = 'C' + moment().format('YYYYMMDD') + '-ZL-'
      } else if (value === '采购部') {
        this.orderOutForm.serialNumber = 'C' + moment().format('YYYYMMDD') + '-CG-'
      } else if (value === '仓储部') {
        this.orderOutForm.serialNumber = 'C' + moment().format('YYYYMMDD') + '-CC-'
      } else if (value === '销售部') {
        this.orderOutForm.serialNumber = 'C' + moment().format('YYYYMMDD') + '-XS-'
      } else {
        this.orderOutForm.serialNumber = ''
      }
    },
    materialPurchaseStockChange (row) {
      row.batchNo = ''
      this.updateBatchNoList(row)
      if (row.materialNo) {
        let materialPurchaseStock = this.materialPurchaseStockList.find(item => item.materialNo === row.materialNo)
        row.materialName = materialPurchaseStock.productName
        row.spec = materialPurchaseStock.spec
      } else {
        row.materialName = ''
        row.spec = ''
      }
    },
    selectMaterialRequisition () {
      if (this.orderOutForm.materialRequisitionNo) {
        this.orderOutForm.serialNumber = ''
        this.orderOutForm.receiveDept = ''
        this.detailList = []
        selectMaterialRequisitionByNo(this.orderOutForm.materialRequisitionNo).then(res => {
          this.orderOutForm.receiveDept = res.applyDept
          if (this.orderOutForm.receiveDept === '生产设备部') {
            this.orderOutForm.serialNumber = 'C' + moment().format('YYYYMMDD') + '-SCSB-'
          } else if (this.orderOutForm.receiveDept === '质量部') {
            this.orderOutForm.serialNumber = 'C' + moment().format('YYYYMMDD') + '-ZL-'
          } else if (this.orderOutForm.receiveDept === '采购部') {
            this.orderOutForm.serialNumber = 'C' + moment().format('YYYYMMDD') + '-CG-'
          } else if (this.orderOutForm.receiveDept === '仓储部') {
            this.orderOutForm.serialNumber = 'C' + moment().format('YYYYMMDD') + '-CC-'
          } else if (this.orderOutForm.receiveDept === '销售部') {
            this.orderOutForm.serialNumber = 'C' + moment().format('YYYYMMDD') + '-XS-'
          } else {
            this.orderOutForm.serialNumber = ''
          }
          this.detailList = res.detailList
        })
      }
    },
    updateBatchNoList (row) {
      let materialNo = row.materialNo
      let batchNoList = []
      if (materialNo) {
        let item = this.cacheBatchNoList.find(item => item.materialNo === materialNo)
        if (item) {
          batchNoList = item.batchNoList
          this.batchNoList = batchNoList
        } else {
          selectMaterialPurchaseList({ materialNo: materialNo, type: 1 }).then(res => {
            batchNoList = res.list
            this.cacheBatchNoList.push({ materialNo, batchNoList })
            this.batchNoList = batchNoList
          })
        }
      } else {
        this.batchNoList = batchNoList
      }
    },
    editActivedEvent ({ row }) {
      this.updateBatchNoList(row)
    }
  }
}
</script>

<style>
</style>
